import app from "/src/app/app.module";
import './dashboard.html';
import moment from "moment";

app.controller('dashboard-grid', function ($scope, settings, $filter, $job, vxUtils,
                                           $api, $auth,
                                           selectionFilters, tabService) {
    $scope.openItems = tabService.tabs;
    $scope.PageHash = vxUtils.generateQuickGuid();
    const tab = tabService.getCurrentTab();
    const id = tab.id; //


    function refreshDashboardData(filterModel) {
        $api.post($api.adress.dashboard+`/${id}`, function (data) {
            $scope.gridConfig = data;
            tab.tabname = $scope.gridConfig.title;
        },filterModel)
    }

    $scope.$on('filterChanged', function(event, filterModel) {
        console.log('Filter changed:', filterModel);
        // Call a function to refresh data with the new filter
        refreshDashboardData(filterModel);
    });

    refreshDashboardData({});
});
