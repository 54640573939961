

import {detectAndStoreLanguage, getSelectedLanguage} from "./shared/lang.service";

import 'jquery'
import angular from 'angular';
import './styles/styles.scss';

//shim for missing function
angular.lowercase = function (value) {
    return value ? value.toLowerCase() : value;
}

import 'angular-sanitize';
import 'angular-animate';
import 'angular-cookies';
import 'angular-route';
import 'angular-touch';

import 'angular-filter'
import 'angular-drag-and-drop-lists'

import 'angular-translate'
import 'angular-resource'
import 'angular-animate'


import 'moment';
import 'moment-timezone';
import 'accounting-js';
import 'sweetalert';
import 'ns-popover';
import 'chart.js';
import 'angular-mousewheel';
import 'js-xlsx';
import 'js-xlsx/jszip';
import 'angular-h-sweetalert';
import 'ngstorage';
import 'leaflet'
import 'ui-leaflet'
import 'leaflet-geosearch'
import './bower_components/hamsterjs/hamster.js';
import './bower_components/ng-table/dist/ng-table';
import 'fullcalendar'
import 'angular-ui-calendar'
import './bower_components/bootstrap-daterangepicker/daterangepicker'
import './bower_components/angular-daterangepicker/js/angular-daterangepicker'
import 'rangy/lib/rangy-core';
import 'rangy/lib/rangy-selectionsaverestore';
import 'textangular/dist/textAngular-sanitize.min.js';
import 'textangular/dist/textAngular.min.js';
import 'lodash';

import './bower_components/angular-autoFields-bootstrap/autofields';
import './bower_components/angular-autoFields-bootstrap/autofields-bootstrap'
import './bower_components/angular-xeditable/dist/js/xeditable'
import './bower_components/bootstrap/dist/js/bootstrap';
import './bower_components/jquery-ui/ui/jquery-ui';
import './bower_components/he/he';
import './bower_components/crosstab/src/crosstab';
import './bower_components/angular-strap/dist/angular-strap';
import './bower_components/angular-strap/dist/angular-strap.tpl';
import './bower_components/angular-ui-bootstrap/ui-bootstrap';
import './bower_components/angular-ui-bootstrap/ui-bootstrap-tpls';

//import './bower_components/angular-chart.js';
import 'angular-chart.js';
import './bower_components/jquery.nicescroll/jquery.nicescroll';

import './bower_components/velocity/jquery.velocity'
import './bower_components/velocity/velocity.ui'
import './bower_components/angular-velocity/angular-velocity'
import './bower_components/angular-loading-bar'
import './bower_components/angular-clear-input/angular.dcb-clear-input'
import './bower_components/angular-mousewheel/mousewheel'
import './bower_components/angular-toastr/dist/angular-toastr'
import './bower_components/angular-multi-select'
import './bower_components/angular-simple-logger' // google maps
import './bower_components/angular-google-maps/dist/angular-google-maps'
import './bower_components/ng-file-upload/angular-file-upload'
import './bower_components/angular-nicescroll/angular-nicescroll'
import './bower_components/angular-mailto/angular-mailto'
import './bower_components/waves/dist/waves'

import appModule from './app/app.module';
import './translate'
import './app/app.config'
import './app/app.services'
import './app/app.routes'
import './app/app.controller'

import {loadLocale} from "./app/app.config";
const selectedLanguage = getSelectedLanguage() || detectAndStoreLanguage();
loadLocale(selectedLanguage);

// Fetch settings with vanilla JavaScript and store them globally
(function () {
    const settingsUrl = '/api/settings';
    const request = new XMLHttpRequest();

    request.open('GET', settingsUrl, true);
    request.onreadystatechange = function () {
        if (request.readyState === 4 && request.status === 200) {
            try {
                window.appSettings = JSON.parse(request.responseText);
                window.appSettings.lang = selectedLanguage;
                // After settings are fetched, bootstrap the AngularJS application
                angular.bootstrap(document, [appModule.name], {
                    strictDi: true
                });

            } catch (e) {
                console.error('Failed to parse settings:', e);
            }
        } else if (request.readyState === 4) {
            console.error('Failed to fetch settings:', request.statusText);
        }
    };
    request.send();
})();



// BELOW THE SCRIP THAT WHERE PREVIOUS LOADED

// <script src="bower_components/jquery/dist/jquery.js"></script>
// <script src="bower_components/jquery.nicescroll/jquery.nicescroll.js"></script>
// <script src="bower_components/hamsterjs/hamster.js"></script>
// <script src="bower_components/moment/moment.js"></script>
// <script src="bower_components/moment-timezone/moment-timezone.js"></script>
// <script src="bower_components/lodash/lodash.min.js"></script>
// <script src="bower_components/accounting.js/accounting.js"></script>
// <script src="bower_components/crosstab/src/crosstab.js"></script>
// <script src="bower_components/he/he.js"></script>
// <script src="bower_components/sweetalert/lib/sweet-alert.min.js"></script>

// <script src="bower_components/js-xlsx/dist/xlsx.full.min.js"></script>

// <!--ANGULAR-->
// <script src="bower_components/angular/angular.js"></script>
// <script src="bower_components/angular-cookies/angular-cookies.js"></script>
// <script src="bower_components/angular-route/angular-route.js"></script>
// <script src="bower_components/angular-translate/angular-translate.js"></script>
// <script src="bower_components/angular-resource/angular-resource.js"></script>
// <script src="bower_components/angular-animate/angular-animate.js"></script>
// <script src="bower_components/ngstorage/ngStorage.min.js"></script>
// <script src="bower_components/angular-mousewheel/mousewheel.js"></script>
// <script src="bower_components/angular-loading-bar/src/loading-bar.js"></script>
// <script src="bower_components/angular-multi-select/angular-multi-select.js"></script>

// <script src="bower_components/angular-drag-and-drop-lists/angular-drag-and-drop-lists.js"></script>
// <script src="bower_components/bootstrap-daterangepicker/daterangepicker.js"></script>
// <script src="bower_components/angular-daterangepicker/js/angular-daterangepicker.js"></script>
// <script src="bower_components/nsPopover/src/nsPopover.js"></script>
// <script src="bower_components/angular-toastr/dist/angular-toastr.js"></script>
// <script src="bower_components/angular-filter/dist/angular-filter.min.js"></script>
// <script src="bower_components/angular-clear-input/angular.dcb-clear-input.js"></script>
// <script src="bower_components/ng-file-upload/angular-file-upload.js"></script>
// <script src="bower_components/angular-h-sweetalert/dist/ngSweetAlert.min.js"></script>
// <script src="bower_components/angular-md5/angular-md5.js"></script>
// <script src="bower_components/angular-mailto/angular-mailto.js"></script>
// <script src="bower_components/angular-touch/angular-touch.min.js"></script>
// <script src="bower_components/angular-nicescroll/angular-nicescroll.js"></script>
//
// <!--BOOTSTRAP-->
// <script src="bower_components/bootstrap/dist/js/bootstrap.js"></script>
// <script src="bower_components/angular-bootstrap/ui-bootstrap.js"></script>
// <script src="bower_components/angular-bootstrap/ui-bootstrap-tpls.js"></script>
//
// <script src="bower_components/angular-strap/dist/angular-strap.js"></script>
// <script src="bower_components/angular-strap/dist/angular-strap.tpl.js"></script>
// <!--TEXTANGULAR-->
// <script src='bower_components/textAngular/dist/textAngular-rangy.min.js'></script>
// <script src="bower_components/textAngular/dist/textAngular-sanitize.min.js"></script>
// <script src='bower_components/textAngular/dist/textAngular.min.js'></script>
// <!--VELOCITY-->
// <script src="bower_components/velocity/jquery.velocity.js"></script>
// <script src="bower_components/velocity/velocity.ui.js"></script>
// <script src="bower_components/angular-velocity/angular-velocity.min.js"></script>
//
// <script src="bower_components/ng-table/dist/ng-table.min.js"></script>
//
// <!--CHARTS-->
// <script src="bower_components/Chart.js/Chart.js"></script>
// <script src="bower_components/angular-chart.js/dist/angular-chart.js"></script>
//
// <!--CALENDER-->
// <script src="bower_components/jquery-ui/ui/jquery-ui.js"></script>
// <script src="bower_components/angular-ui-calendar/src/calendar.js"></script>
// <script src="bower_components/fullcalendar/dist/fullcalendar.js"></script>
// <script src="bower_components/fullcalendar/dist/gcal.js"></script>
//

//
// <link href="bower_components/angular-xeditable/dist/css/xeditable.css" rel="stylesheet"/>
// <script src="bower_components/angular-xeditable/dist/js/xeditable.js"></script>
//
//
// <script src="https://maps.googleapis.com/maps/api/js?sensor=false'"></script>
// <script src="bower_components/bluebird/js/browser/bluebird.js"></script>
// <script src="bower_components/angular-google-maps/dist/angular-google-maps.min.js"></script>
// <script src="bower_components/angular-simple-logger/dist/index.js"></script>
