import app from "/src/app/app.module";


app.controller('dashboard-selection',
    function ($scope, msgBusActions, msgBus, $filter) {

        $scope.newItems = [
            {
                label: $filter('translate')('USER_RESPONDS'),
                description: $filter("translate")('USER_RESPONDS_DESC'),
                icon: "glyphicon glyphicon-user",
                id: 'UserResponseDashboard'
            }
        ];

        //msgBus.onMsg('show.wizard.page', function (sender, msg) {
        //    $scope.openWizard(msg);
        //}, $scope);


        $scope.openDashboard = function (newItem) {
            //var win = window.open('#/create/' + newItem.id, '_blank');
            //win.focus();
            msgBus.emitMsg(msgBusActions.shellOpenTabByType, {
                type: 'dashboard-grid', id: newItem.id, icon: newItem.icon
            });
        };
    }
);