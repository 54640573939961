'use strict';

var EmailSettings = function (settings) {
    if (!settings) return;

    this.dbo = settings;

    this.SENDER = function () {
        return this.dbo['SENDER'];
    };

    this.SENDERNAME = function () {
        return this.dbo['SENDERNAME'];
    };

    this.SMTP_HOST = function () {
        return this.dbo['SMTP_HOST'];
    };

    this.SMTP_PASS = function () {
        return this.dbo['SMTP_PASS'];
    };

    this.SMTP_PORT = function () {
        return parseInt(this.dbo['SMTP_PORT']);
    };

    this.SMTP_USER = function () {
        return this.dbo['SMTP_USER'];
    };

    this.USE_SMTP = function () {
        return this.dbo['USE_SMTP'] === "true";
    };

    this.USE_SMTP_AUTH = function () {
        return this.dbo['USE_SMTP_AUTH'] === "true";
    };

    // Assign values to object properties
    this.SENDER = this.SENDER();
    this.SENDERNAME = this.SENDERNAME();
    this.SMTP_HOST = this.SMTP_HOST();
    this.SMTP_PASS = this.SMTP_PASS();
    this.SMTP_PORT = this.SMTP_PORT();
    this.SMTP_USER = this.SMTP_USER();
    this.USE_SMTP = this.USE_SMTP();
    this.USE_SMTP_AUTH = this.USE_SMTP_AUTH();
};

export default EmailSettings;
