import './shell.html';
import app from "/src/app/app.module";
import {getAvailableLanguages, getSelectedLanguage, setSelectedLanguage} from "../../shared/lang.service";
import moment from "moment";
import ChangelogEntry from "../../models/changelog-entry";

app.controller('shell',
    function ($scope, settings, enums, msgBus, $http, $timeout, $filter,
              msgBusActions, $users, tabService, $api, toastr, $interval, sweet,
              $location, $routeParams, $customer, vxUtils, vxSession, $locale) {

        $scope.locale = $locale;
        $scope.selectedLanguage = getSelectedLanguage();
        $scope.languages = getAvailableLanguages();

        if (angular.isUndefined(vxSession.user)
            || vxSession.user === null
            || vxSession.user.idUser === null) {
            $location.path('login');
            return;
        }


        $scope.session = vxSession;
        $scope.openItems = tabService.tabs;
        $scope.menuitems = [];
        $scope.isDetailsPage = false;

        $scope.nicescrolConf = {
            "cursorcolor": "#bdbdbd",
            "background": "#ffffff",
            "cursorwidth": "7px",
            "cursorborder": "none",
            "cursorborderradius": "2px",
            "zindex": 9998,
            "autohidemode": 'hidden',
            "bouncescroll": true,
            " touchbehavior": false,
            "mousescrollstep": 20
        };

        $scope.trackNotification = function (alert) {
            if (!alert)
                return 0;

            return alert.id

        }

        $scope.changeLanguage = function (clickedLanguage) {
            setSelectedLanguage(clickedLanguage);
            window.location.reload();
        }

        $scope.openTab = function (item) {

            var isInArray = $scope.openItems.indexOf(item);
            if (isInArray == -1) {
                item.active = true;
                $scope.openItems.push(item);
            } else {
                console.log(item.hash + ' false');
                // msgBus.emitMsg(msgBusActions.suspend + item.hash, "false");
                $scope.currentItem.suspended = false;
                $scope.openItems[isInArray].active = true;
            }
            document.title = item.tabname;
        };
        $scope.changeSelection = function (item) {
            if (item === $scope.currentItem)
                return;

            if ($scope.currentItem != null) {
                $scope.currentItem.active = false;
                console.log($scope.currentItem.hash + ' true');
                // msgBus.emitMsg(msgBusActions.suspend + $scope.currentItem.hash, "true");
                $scope.currentItem.suspended = true;
            }
            $scope.currentItem = item;
            $scope.currentItem.active = true;
            //  $location.path(item.path, false);

            $scope.openTab(item);


        };

        function openTabByType(type, id, override, samepage) {
            var tab = {
                hash: vxUtils.generateQuickGuid(),
                tabname: vxUtils.loc('OPENING'),
                type: type,
                id: id,
                path: type + '/' + id
            };
            var newTabs = false;

            switch (type) {
                case 'customer':
                    tab.href = "/src/pages/customers/customer-details.html";
                    tab.icon = "glyphicon glyphicon-user";
                    tab.refresh = true;
                    break;

                case 'job':
                    tab.href = "/src/pages/jobs/job-details.html";
                    tab.icon = "fa fa-map-marker";
                    tab.refresh = true;
                    break;
                case 'order':
                    tab.href = "/src/pages/orders/order-details.html";
                    tab.icon = "fa fa-truck";
                    tab.refresh = true;
                    break;

                case 'payment':
                case 'payment_item':
                    tab.href = "/src/pages/payments/payment-details.html";
                    tab.icon = "fa fa-eur";
                    tab.refresh = true;
                    break;

                case 'employee':
                    tab.href = "/src/pages/employees/employee-details.html";
                    tab.icon = "fa fa-user";
                    tab.refresh = true;
                    break;

                case 'product':
                    tab.href = "/src/pages/products/product-details.html";
                    tab.icon = "fa fa-shopping-cart";
                    tab.refresh = true;
                    break;
                case 'create':
                    tab.href = "/src/pages/wizard/wizard.html";
                    break;
                case 'dashboard-grid':
                    tab.href = "/src/pages/dashboard-grid/dashboard.html";
                    tab.icon = "glyphicon glyphicon-stats";
                    tab.refresh = true;
                    break;
                case 'documentation':
                    tab.href = "/src/pages/documentation/documentation.html";
                    tab.icon = "glyphicon glyphicon-book";
                    tab.refresh = true;
                    break;

            }

            tab.showInTab = true;

            if (!newTabs) {
                // $location.path(tab.path, false);joj
                $scope.openTab(tab);
            } else {
                if (samepage)
                    $location.path(type + '/' + id);
                else if (samepage === false) {
                    var win = window.open('/' + type + '/' + id, '_blank');
                    win.focus();
                    return;
                }
                $scope.isDetailsPage = true;
                $scope.changeSelection(tab);
            }

        }

        $scope.checkTab = function (tabitem) {
            if (tabitem.refresh === undefined)
                return true;
            else if (tabitem.active && tabitem.refresh === true)
                return true;
            else
                return false;
        };

        $scope.generateQuickGuid = function () {
            return Math.random().toString(36).substring(2, 15) +
                Math.random().toString(36).substring(2, 15);
        };

        var shellitems = [
            {
                label: vxUtils.loc('HOME'),
                href: "/src/pages/new-item/new-item.html",
                icon: "ionicons ion-android-home",
                tabname: vxUtils.loc('OYSTERPLANNING'),
                visible: $scope.session.auth(2),
                hash: $scope.generateQuickGuid(),
                showInTab: false
            },
            {
                label: vxUtils.loc('DASHBOARD'),
                tabname: vxUtils.loc('DASHBOARD'),
                href: "/src/pages/dashboard/dashboard.html",
                icon: "glyphicon glyphicon-dashboard",
                hash: $scope.generateQuickGuid(),
                visible: $scope.session.auth(2)

            },
            {
                label: vxUtils.loc('STATS'),
                tabname: vxUtils.loc('STATS'),
                href: "/src/pages/dashboard-selection/dashboard-selection.html",
                icon: "glyphicon glyphicon-stats",
                hash: $scope.generateQuickGuid(),
                visible: $scope.session.auth(2)
            },
            {
                label: vxUtils.loc('RELATIONS'),
                href: "/src/pages/customers/customers.html",
                icon: "fa fa-group ",
                tabname: vxUtils.loc('RELATIONS'),
                visible: $scope.session.auth(2),
                hash: $scope.generateQuickGuid(),
                showInTab: false
            },
            {
                label: vxUtils.loc('HOME'),
                href: "/src/pages/employees/employee-details.html",
                icon: "ionicons ion-android-home",
                visible: $scope.session.authEquals(1),
                hash: $scope.generateQuickGuid(),
                showInTab: false,
                id: $scope.session.user.idUser,
                type: 'home'
            },
            {
                label: vxUtils.loc('PAYMENTS'),
                href: "/src/pages/payments/payments.html",
                icon: "fa fa-eur",
                tabname: vxUtils.loc('PAYMENTS'),
                visible: $scope.session.auth(2),
                hash: $scope.generateQuickGuid(),
                showInTab: false,
            },
            {
                label: vxUtils.loc('CALENDAR'), href: "/src/pages/calender/calender.html",
                icon: "fa fa-calendar ",
                tabname: vxUtils.loc('CALENDAR'),
                hash: $scope.generateQuickGuid(),
                visible: $scope.session.authEquals(1),
                showInTab: false,
                calendarType: {
                    item: 'employee',
                    id: $scope.session.user.idUser
                }
            },
            {
                label: vxUtils.loc('CALENDAR'), href: "/src/pages/calender/calender.html",
                icon: "fa fa-calendar ",
                tabname: vxUtils.loc('CALENDAR'),
                hash: $scope.generateQuickGuid(),
                visible: $scope.session.auth(2),
                showInTab: false,
                calendarType: {
                    item: 'jobs',
                    id: $scope.session.user.idUser
                }
            },
            {
                label: vxUtils.loc('JOBS'),
                href: "/src/pages/jobs/jobs.html",
                icon: "fa fa-map-marker",
                tabname: vxUtils.loc('JOBS'),
                visible: $scope.session.auth(2),
                hash: $scope.generateQuickGuid(),
                showInTab: false
            }, {
                label: vxUtils.loc('ORDERS'),
                href: "/src/pages/orders/orders.html",
                icon: "fa fa-truck",
                tabname: vxUtils.loc('ORDERS'),
                visible: $scope.session.auth(2),
                hash: $scope.generateQuickGuid(),
                showInTab: false
            },
            {
                label: vxUtils.loc('PRODUCTS'),
                href: "/src/pages/products/products.html",
                icon: "fa fa-shopping-cart",
                tabname: vxUtils.loc('PRODUCTS'),
                visible: $scope.session.auth(2),
                hash: $scope.generateQuickGuid(),
                showInTab: false
            },

            //     { label: 'Takenlijst', href: "/src/pages/inprogress/inprogress.html", icon: "fa fa-tasks", hash: $scope.generateQuickGuid() },
            {
                label: $scope.session.auth(2) ? vxUtils.loc('EMPLOYEES') : vxUtils.loc('COLLEAGUES'),
                href: "/src/pages/employees/employees.html",
                icon: "fa fa-user",
                tabname: $scope.session.auth(2) ? vxUtils.loc('EMPLOYEES') : vxUtils.loc('COLLEAGUES'),
                visible: ($scope.session.auth(2) || $scope.session.auth(1)),
                hash: $scope.generateQuickGuid(),
                showInTab: false
            },
            {
                label: vxUtils.loc('WORKNOTES'),
                href: "/src/pages/employees/employees.html",
                icon: "glyphicon glyphicon-time",
                tabname: vxUtils.loc('WORKNOTES'),
                visible: $scope.session.auth(2),
                type: 'worknote',
                hash: $scope.generateQuickGuid(),
                showInTab: false
            },
            //    { label: 'Business Inteligence', href: "/src/pages/inprogress/inprogress.html", icon: "fa fa-pie-chart", hash: $scope.generateQuickGuid() },
            //     { label: 'Mee bezig', href: "/src/pages/inprogress/inprogress.html", icon: "fa fa-pencil-square ", hash: $scope.generateQuickGuid() },
            {
                label: vxUtils.loc('SETTINGS'),
                href: "/src/pages/settings/settings.html",
                icon: "fa fa-cogs ",
                tabname: vxUtils.loc('SETTINGS'),
                visible: $scope.session.auth(2),
                hash: $scope.generateQuickGuid(),
                showInTab: false
            },
            {
                label: vxUtils.loc('DOCUMENTATION'),
                href: "/src/pages/documentation/documentation.html",
                icon: "fa fa-book ",
                tabname: vxUtils.loc('DOCUMENTATION'),
                visible: $scope.session.auth(2),
                hash: $scope.generateQuickGuid(),
                showInTab: false
            },
            {
                label: vxUtils.loc('MY_PROFILE'),
                href: "/src/pages/employees/employee-details.html",
                icon: "fa fa-cogs",
                tabname: vxUtils.loc('MY_PROFILE'),
                visible: $scope.session.authEquals(1),
                hash: $scope.generateQuickGuid(),
                id: $scope.session.user.idUser,
                type: 'settings',
                showInTab: false
            }

        ];


        $scope.checkMiddleButton = function ($event, $index) {
            if ($event.button === 1) {
                $scope.removeTab($index);
            }
        };
        setTimeout(function () {
            angular.forEach(shellitems, function (v, k) {
                if (v.visible)
                    $scope.menuitems.push(v);
            });

            $scope.openItems.push($scope.menuitems[$scope.session.authEquals(1) ? 0 : 1]);
        }, 300);

        $scope.opendItem = function (item) {
            $scope.currentItem = item;
        };

        $scope.openItem = function (item) {
            if ($scope.currentItem != null) {
                $scope.currentItem.$selected = false;
            }
            $scope.currentItem = item;
            $scope.currentItem.$selected = true;
            $scope.openItems.active = true;
            $scope.openItems.push(item);
        }

        $scope.removeTab = function (index) {
            var tab = $scope.openItems[index];
            tab.active = false;
            //     tab.$destroy();
            $scope.openItems.splice(index, 1);
        };

        $scope.getTemplateUrl = function (tabitem) {
            return tabitem.href;
        };


        ////////TABWORKINGS/////

        $scope.logout = function () {
            vxSession.destroy();
            $location.path('login');
        };


        $scope.notifications = [];

        $scope.recentChanges = [];

        $scope.clearItemFromNotification = function (alert, index) {
            var n = {
                done: 1,
                claimed_by: vxSession.user.idUser,
                claimed_date: moment().unix(),
                id: alert.dbo['id'],
                read: 1
            };

            $api.post($api.adress.notifications, function () {
                $scope.notifications.splice(index, 1);
                toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('NOTIFICATION') + vxUtils.loc('ACTION_HANDLED'));
            }, n);

        };

        $scope.clearAllItemsFromNotification = function () {
            angular.forEach($scope.notifications, function (v, k) {
                $scope.clearItemFromNotification(v, k);
            });
            $scope.notifications = [];
        };

        $scope.getNotifications = function () {
            $scope.notifications = [];
            $users.getNotifications(function (results) {
                $scope.notifications = $filter('orderBy')(results, 'executiondate');
                setTimersForCalls($scope.notifications);
            });

        };
        var callTimers = [];
        var setTimersForCalls = function (notifications) {
            angular.forEach(notifications, function (v, k) {
                var index = k;
                if (v.type.indexOf('call_planned') > -1) {
                    if (!callTimers[v.hash]) {

                        var dif = moment(v.executiondate).diff(moment());
                        var timer = $timeout(function () {
                            $api.get($api.adress.phoneNumbers + '/' + v.tabID + '/' + (v.type.indexOf('job') > -1 ? 'job' : 'client'),
                                function (results) {
                                    sweet.show({
                                        title: vxUtils.loc('CALLAPPOINTMENT'),
                                        text: vxUtils.loc('CALLAPPOINTMENTTEXT')
                                            .replace('$jobtitle', v.tabID + ', ' + v.message)
                                            .replace('$tel', results.join(' of '))
                                            .replace('$time', moment(v.executiondate).format($locale.DATETIME_FORMATS.shortTime))
                                            .replace('$remarks', v.remarks),
                                        type: 'warning',
                                        showCancelButton: true,
                                        confirmButtonColor: '#DD6B55',
                                        confirmButtonText: vxUtils.loc('DONE'),
                                        cancelButtonText: vxUtils.loc('IGNORE'),
                                        closeOnConfirm: true
                                    }, function (isConfirm) {
                                        if (isConfirm) {
                                            $scope.clearItemFromNotification(v, index);
                                            $timeout.cancel(callTimers[v.dbo.hash].timer);
                                        } else {
                                            $timeout.cancel(callTimers[v.dbo.hash].timer);
                                        }
                                    });
                                });

                        }, dif);
                        v.timer = timer;
                        callTimers[v.dbo.hash] = v;

                    }
                }
            });
        };

        $scope.setNotificationsRead = function () {
            $timeout(function () {
                angular.forEach($scope.notifications, function (value, key) {
                    //          value.new = false;
                });
            }, 3000);

        };

        $scope.openNotification = function (alert, index) {
            var type = '';
            var override = undefined;
            switch (alert.type) {
                case 'call_planned_job':
                case 'job':
                    type = 'job';
                    break;
                case 'call_planned_client':
                    type = 'customer';
                    break;
                case 'invoice':
                case 'quote':
                case 'payment_item':
                    type = 'payment_item';
                    override = {};
                    override.id = alert.targetid;
                    override.descr = alert.message;
                    break;
            }
            openTabByType(type, alert.targetid, override);
        };

        $scope.getRecentChanges = function () {

            $scope.recentChanges = [
                {
                    icon: 'fa fa-comment fa-fw',
                    title: 'New Comment',
                    timestamp: '4 minutes ago',
                    new: true
                },
                {
                    icon: 'fa fa-comment fa-fw',
                    title: 'New Comment',
                    timestamp: '10 minutes ago',
                    new: false
                },
                {
                    icon: 'fa fa-comment fa-fw',
                    title: 'New Comment',
                    timestamp: '20 minutes ago',
                    new: false
                }
            ];
        };

        $scope.openSearchItem = function (item, model, label) {
            if (model === undefined)
                return;

            if ($filter('startsWith')(model.Type, 'customer'))
                model.Type = 'customer';

            if (model.Type == "invoice") {
                console.log("Type is invoice,", item, model);
                model.Type = "job";
                model.ID = item.JobID;
            }

            openTabByType(model.Type, model.ID, null, false);

        };

        $scope.getSearchResults = function (val) {
            function getIconClass(type) {
                switch (type) {
                    case 'customer-i':
                        return 'glyphicon glyphicon-user';
                    case 'invoice':
                        return 'fa fa-money';
                    case 'customer-c':
                        return 'fa fa-building-o';
                    case 'job':
                        return 'fa fa-map-marker';
                    case 'payment':
                        return 'fa fa-eur';
                    case 'employee':
                        return 'fa fa-users';
                    default:
                        return ''; // Default case if none of the types match
                }
            }



            if (val.length > 0)
                return $http.post(settings.BASE_API_URL + 'v1/search/' + val,
                    '["customer","job","invoice", "employee"]').then(function (response) {
                    return response.data.map(function(value){
                        return {
                            TypeIcon: getIconClass(value.Type),
                            Type: value.Type,
                            Descr: value.Type==='job'?$filter('date')(parseInt(value.Descr+"000"), $locale.DATETIME_FORMATS.short):value.Descr,
                            ID:value.ID,
                            Summary: value.Type==='invoice'?$filter('date')(parseInt(value.Summery+"000"), $locale.DATETIME_FORMATS.short):value.Summery,
                            Result: value.Result,
                            JobID: value.JobID
                        }
                    });
                });
        };

        msgBus.onMsg(msgBusActions.shellCloseTabByGuid, function (sender, mssg) {
            var item = $filter('filter')($scope.openItems, {hash: mssg.guid});
            var index = $scope.openItems.indexOf(item[0]);
            $scope.removeTab(index);
        }, $scope);

        msgBus.onMsg(msgBusActions.shellOpenTabByType, function (sender, mssg) {
            openTabByType(mssg.type, mssg.id, mssg.tab, true);
        }, $scope);

        $scope.getNotifications();
        $scope.getRecentChanges();

        //if ($location.path().indexOf('create') > -1) {
        //    var id = 0;
        //    openTabByType('create', id);
        //}
        //else if ($location.path().indexOf('customer') > -1) {
        //    var id = $routeParams.id;
        //    openTabByType('customer', id);
        //}
        //else if ($location.path().indexOf('job') > -1) {
        //    var id = $routeParams.id;
        //    openTabByType('job', id);
        //}
        //else if ($location.path().indexOf('employee') > -1) {
        //    var id = $routeParams.id;
        //    openTabByType('employee', id);
        //}
        //else if ($location.path().indexOf('product') > -1) {
        //    var id = $routeParams.id;
        //    openTabByType('product', id);
        //}
        //else if ($location.path().indexOf('payment') > -1) {
        //    var id = $routeParams.id;
        //    openTabByType('payment_item', id);
        //}
        //else
        var path = $location.path();
        if ($location.path().indexOf('newclient') > -1) {
            var email = $routeParams.email;
            var name = $routeParams.name;
            var timer = $timeout(function () {
                msgBus.emitMsg('show.wizard.page',
                    {
                        label: vxUtils.loc('CUSTOMER'),
                        icon: "fa fa-building-o",
                        id: 'customer',
                        customer: {
                            email: email,
                            name: name
                        }
                    });
            }, 500);

            $scope.$on(
                "$destroy",
                function (event) {
                    $timeout.cancel(timer);
                }
            );

        }

        $scope.forceReload = function () {
            window.location.reload(true);
        };

        var stopTime = $interval($scope.getNotifications, 60000);

        // listen on DOM destroy (removal) event, and cancel the next UI update
        // to prevent updating time after the DOM element was removed.
        $scope.$on('$destroy', function () {
            $interval.cancel(stopTime);
        });

        // Function to fetch changelog and show popup if needed
        function checkForNewChangelog() {
            const changelogUrl = $api.adress.changelog; // API endpoint for changelog
            const lastReadVersion = localStorage.getItem("lastReadVersion") || "0.0.0";

            $api.get(changelogUrl, function (response) {
                if (response && response.length > 0) {
                    const latestEntry = response[0]; // Get the latest changelog entry
                    const latestVersion = latestEntry._version;

                    if (isNewVersion(latestVersion, lastReadVersion)) {
                        showChangelogPopup(latestEntry);
                        localStorage.setItem("lastReadVersion", latestVersion); // Store new version
                    }
                }
            },ChangelogEntry ,function () {
                console.error("Failed to load changelog from API.");
            },false);
        }

        // Compare versions (e.g., "1.2.0" > "1.1.9")
        function isNewVersion(latest, stored) {
            return latest.localeCompare(stored, undefined, { numeric: true }) > 0;
        }

        // Show changelog popup
        function showChangelogPopup(entry) {
            toastr.info(entry._message, `New Version ${entry._version} - ${entry._date}`, {
                closeButton: true,
                timeOut: 0,
                extendedTimeOut: 0,
                allowHtml: true,
                tapToDismiss: false,
                toastClass: "new-version-toast"
            });
        }

        // Only show changelog if user is admin (auth level 2)
        if ($scope.session.auth(2)) {
            checkForNewChangelog();
        }

    });
