import dashboardModule from "./vxPanelGrid.module";

dashboardModule.directive('gridPanels', function() {
    return {
        restrict: 'E',
        scope: {
            config: '='
        },
        templateUrl: '/src/directives/vxPanelGrid/vxPanelGrid.html',  // Reference to the external HTML template
        link: function(scope, element) {
            scope.getColWidth = function(n) {
                // If 12 divides evenly by n, use that; otherwise, use Math.floor.
                if (12 % n === 0) {
                    return 12 / n;
                } else {
                    return Math.floor(12 / n);
                }
            };
        }

    };
});