import dashboardModule from "../vxPanelGrid.module";

dashboardModule.directive('tablePanel', function() {
    return {
        restrict: 'E',
        scope: {
            data: '='
        },
        template: `
      <table class="table table-condensed table-hover  table-striped">
        <thead>
          <tr>
            <th ng-repeat="header in data.headers">{{header.label}}</th>
          </tr>
        </thead>
        <tbody>
          <tr ng-repeat="row in data.rows">
            <td ng-repeat="cell in row">{{cell}}</td>
          </tr>
        </tbody>
      </table>
    `
    };
});