/**
 * Created by Alex Knijf on 20-2-2015.
 */
import app from "/src/app/app.module";
import {detectAndStoreLanguage, getSelectedLanguage} from "../shared/lang.service";

export const loadLocale = function (lng) {

    require('../translate/angular-locale_' + lng + '.js');

};

app.service('settings', function () {
    this.BASE_API_URL = "/api/";
    //this.BASE_API_URL = window.location.protocol + "//" + document.domain + "/api/";//"https://localhost/";
    this.VOXIL_CONNECT_API_URL = "https://localhost:8089/";
    this.currency= window.appSettings.currency;
    this.lang = window.appSettings.lang;
    this.version = window.appSettings.version;
    this.buildDate = new Date(window.appSettings.buildDate);
    this.buildNumber = window.appSettings.buildNumber;
    this.gitCommitId = window.appSettings.gitCommitId;
});

app.factory('langInterceptor', ['settings', function(settings) {
    return {
        request: function(config) {
            // Add the language header if settings.lang is set
            if (settings.lang) {
                config.headers['X-Lang'] = settings.lang;
            }
            return config;
        }
    };
}]);

app.config(['$httpProvider', function($httpProvider) {
    $httpProvider.interceptors.push('langInterceptor');
}]);

// todo remove when prod
app.config(['$compileProvider', function ($compileProvider) {
    $compileProvider.debugInfoEnabled(true);
}]);
app.factory('$exceptionHandler', function () {
    return function (exception, cause) {
        console.error(exception)
        console.error(cause)
    };
});
app.config(function ($provide) {
    function createTable(tableParams) {
        if (angular.isNumber(tableParams.row) && angular.isNumber(tableParams.col)
            && tableParams.row > 0 && tableParams.col > 0) {
            var table = "<table class='table table-bordered "
                + (tableParams.style ? "border-" + tableParams.style : '')
                + "'>";

            var colWidth = 100 / tableParams.col;
            for (var idxRow = 0; idxRow < tableParams.row; idxRow++) {
                var row = "<tr>";
                for (var idxCol = 0; idxCol < tableParams.col; idxCol++) {
                    row += "<td"
                        + (idxRow == 0 ? ' style="width: ' + colWidth + '%;"' : '')
                        + ">&nbsp;</td>";
                }
                table += row + "</tr>";
            }
            return table + "</table>";
        }
    }

    $provide.decorator('taOptions', ['taRegisterTool', '$delegate', '$modal', '$rootScope', function (taRegisterTool, taOptions, $modal, $rootScope) {
        taRegisterTool('insertTable', {
            iconclass: 'fa fa-table',
            tooltiptext: 'Insert table',
            action: function (promise, restoreSelection) {
                var obj = {
                    col: 3,
                    row: 2,
                    style: "solid"
                };
                restoreSelection();
                var embed = createTable(obj);
                return this.$editor().wrapSelection('insertHTML', embed, false);
            }
        });
        // add the button to the default toolbar definition
        taOptions.toolbar[1].push('insertTable');
        return taOptions;
    }])
});
app.config(['$httpProvider', 'cfpLoadingBarProvider',
    function ($httpProvider, cfpLoadingBarProvider) {

        //Enable cross domain calls
        $httpProvider.defaults.useXDomain = true;
        //Remove the header used to identify ajax call  that would prevent CORS from working
        delete $httpProvider.defaults.headers.common['X-Requested-With'];

        // cfpLoadingBarProvider.includeSpinner = true;
        $httpProvider.defaults.withCredentials = true;

        $httpProvider.interceptors.push('AuthInterceptor');
        $httpProvider.interceptors.push('ErrorResponseInterceptor');

    }]);

app.config(function (datepickerConfig, datepickerPopupConfig, $autofieldsProvider,
                     dateRangePickerConfig, timepickerConfig, $timepickerProvider,
                     $datepickerProvider, $localeProvider) {

// Example usage:

    const $locale = $localeProvider.$get();

    datepickerPopupConfig.datepickerPopup = $locale.DATETIME_FORMATS.shortDate;

    $autofieldsProvider.settings.dateSettings = {
        startingDay: 1,
        showWeeks: true,
        datepickerPopup: datepickerPopupConfig.datepickerPopup
    };
    $autofieldsProvider.settings.scope.datepickerOptions = {
        showWeeks: true
    };

    datepickerConfig.startingDay = 1;
    datepickerConfig.showWeeks = true;
    datepickerConfig.datepickerPopup =  $locale.DATETIME_FORMATS.shortDate;
    dateRangePickerConfig.format = $locale.DATETIME_FORMATS.shortDate.replace(/dd/g, 'DD');


    timepickerConfig.showMeridian=  ($locale.DATETIME_FORMATS.shortTime || '').indexOf('h') > -1;
    // datepickerPopupConfig.formatDayTitle = "dd-MM-yyyy";
    // datepickerPopupConfig.currentText = $filter('translate')('TODAY');
    // datepickerPopupConfig.clearText = $filter('translate')('EMPTY');
    // datepickerPopupConfig.closeText = $filter('translate')('CLOSE');

    angular.extend($datepickerProvider.defaults, {
        dateFormat:  $locale.DATETIME_FORMATS.shortDate,
        startWeek: 1
    });

    angular.extend($timepickerProvider.defaults, {
        showMeridian :  ($locale.DATETIME_FORMATS.shortTime || '').indexOf('h') > -1
    });
});


app.config(['$localeProvider', '$autofieldsProvider', function ($localeProvider ,$autofieldsProvider) {
    // Checkbox Field Handler
    const $locale = $localeProvider.$get();
    // Determine whether the locale uses a 12-hour or 24-hour time format
    const is12HourFormat = ($locale.DATETIME_FORMATS.shortTime || '').indexOf('h') > -1;

    // Timepicker Field Handler
    $autofieldsProvider.registerHandler('time', function (directive, field, index) {
        const inputAttrs = {
            showMeridian: is12HourFormat  // True for 12-hour format (AM/PM), false for 24-hour format
        };

        const fieldElements = $autofieldsProvider.field(directive, field, '<timepicker/>', inputAttrs);
        fieldElements.input.removeClass('form-control');
        return fieldElements.fieldContainer;
    });
}]);


app.config(['$autofieldsProvider', function ($autofieldsProvider) {
    // Checkbox Field Handler
    $autofieldsProvider.registerHandler('typeahead', function (directive, field, index) {
        var typeahead = field.typeahead ? field.typeahead : [];

        var inputAttrs = {
            type: 'text',
            typeahead: typeahead
        };

        var fieldElements = $autofieldsProvider.field(directive, field, '<input />', inputAttrs);
        // fieldElements.input.removeClass('form-control');
        return fieldElements.fieldContainer;
    });
}]);


app.run(function (editableOptions, editableThemes, $route, $rootScope, $location, $translate, settings, $templateCache,
                USER_ROLES, $auth, selectionFilters, $users, $api, $filter, datepickerPopupConfig, vxSession, $job, $payment, $products, $depot, $customer) {
    // setup templatecache for ng-include
    $templateCache.put('/src/directives/vxPanelGrid/vxPanelGrid.html', require('/src/directives/vxPanelGrid/vxPanelGrid.html'));
    $templateCache.put('/src/directives/vxFloatInput/vxFloatInput.html', require('/src/directives/vxFloatInput/vxFloatInput.html'));
    $templateCache.put('/src/directives/vxFloatInput/vxFloatTextArea.html', require('/src/directives/vxFloatInput/vxFloatTextArea.html'));
    $templateCache.put('/src/directives/vxInputLabel/vxInputLabel.html', require('/src/directives/vxInputLabel/vxInputLabel.html'));
    $templateCache.put('/src/directives/vxRippleToggleButton/vxRippleToggleButton.html', require('/src/directives/vxRippleToggleButton/vxRippleToggleButton.html'));
    $templateCache.put('/src/directives/vxTimeLine/vxTimeLine.html', require('/src/directives/vxTimeLine/vxTimeLine.html'));
    $templateCache.put('/src/directives/vxWizard/vxWizard.html', require('/src/directives/vxWizard/vxWizard.html'));


    $templateCache.put('/src/modules/vxActionBar/vxActionBar.html', require('/src/modules/vxActionBar/vxActionBar.html'));
    $templateCache.put('/src/modules/vxActionBar/vxActionBarScroll.html', require('/src/modules/vxActionBar/vxActionBarScroll.html'));
    $templateCache.put('/src/modules/vxAdress/vxAdress.html', require('/src/modules/vxAdress/vxAdress.html'));
    $templateCache.put('/src/modules/vxAvailability/vxAvailability.html', require('/src/modules/vxAvailability/vxAvailability.html'));
    $templateCache.put('/src/modules/vxCustomer/vxCustomer.html', require('/src/modules/vxCustomer/vxCustomer.html'));
    $templateCache.put('/src/modules/vxOrder/vxOrder.html', require('/src/modules/vxOrder/vxOrder.html'));
    $templateCache.put('/src/modules/vxEmployee/vxEmployee.html', require('/src/modules/vxEmployee/vxEmployee.html'));
    $templateCache.put('/src/modules/vxExtendablePanel/vxExtendablePanel.html', require('/src/modules/vxExtendablePanel/vxExtendablePanel.html'));
    $templateCache.put('/src/modules/vxFilterbar/vxFilterbar.html', require('/src/modules/vxFilterbar/vxFilterbar.html'));
    $templateCache.put('/src/modules/vxJob/vxJob.html', require('/src/modules/vxJob/vxJob.html'));
    $templateCache.put('/src/modules/vxList/vxList.html', require('/src/modules/vxList/vxList.html'));
    $templateCache.put('/src/modules/vxOverview/vxOverview.html', require('/src/modules/vxOverview/vxOverview.html'));
    $templateCache.put('/src/modules/vxPayment/vxPayment.html', require('/src/modules/vxPayment/vxPayment.html'));
    $templateCache.put('/src/modules/vxProduct/vxProduct.html', require('/src/modules/vxProduct/vxProduct.html'));
    $templateCache.put('/src/modules/vxProducts/vxProducts.html', require('/src/modules/vxProducts/vxProducts.html'));
    $templateCache.put('/src/modules/vxSearchPanel/vxSearchPanel.html', require('/src/modules/vxSearchPanel/vxSearchPanel.html'));
    $templateCache.put('/src/modules/vxWorknotes/vxWorknotes.html', require('/src/modules/vxWorknotes/vxWorknotes.html'));

    $templateCache.put('/src/itemtemplates/forms/errorFormMessages.html', require('/src/itemtemplates/forms/errorFormMessages.html'));
    $templateCache.put('/src/itemtemplates/search/availability.tpl.html', require('/src/itemtemplates/search/availability.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/car.tpl.html', require('/src/itemtemplates/search/car.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/change.tpl.html', require('/src/itemtemplates/search/change.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/email.tpl.html', require('/src/itemtemplates/search/email.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/emp.hour.rate.tpl.html', require('/src/itemtemplates/search/emp.hour.rate.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/employee.tpl.html', require('/src/itemtemplates/search/employee.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/emptylist.tpl.html', require('/src/itemtemplates/search/emptylist.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/job.tpl.html', require('/src/itemtemplates/search/job.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/search.default.tpl.html', require('/src/itemtemplates/search/search.default.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/search-itemtemplates.scss', require('/src/itemtemplates/search/search-itemtemplates.scss'));
    $templateCache.put('/src/itemtemplates/search/timeline.tpl.html', require('/src/itemtemplates/search/timeline.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/worknote.tpl.html', require('/src/itemtemplates/search/worknote.tpl.html'));
    $templateCache.put('/src/itemtemplates/search/order.tpl.html', require('/src/itemtemplates/search/order.tpl.html'));


    $templateCache.put('/src/pages/new-item/new-item.html', require('/src/pages/new-item/new-item.html'));
    $templateCache.put('/src/pages/dashboard/dashboard.html', require('/src/pages/dashboard/dashboard.html'));
    $templateCache.put('/src/pages/dashboard-grid/dashboard.html', require('/src/pages/dashboard-grid/dashboard.html'));
    $templateCache.put('/src/pages/customers/customers.html', require('/src/pages/customers/customers.html'));
    $templateCache.put('/src/pages/customers/client-contact-form.html', require('/src/pages/customers/client-contact-form.html'));
    $templateCache.put('/src/pages/customers/company-contact-form.html', require('/src/pages/customers/company-contact-form.html'));
    $templateCache.put('/src/pages/customers/customer-details.html', require('/src/pages/customers/customer-details.html'));
    $templateCache.put('/src/pages/orders/orders.html', require('/src/pages/orders/orders.html'));
    $templateCache.put('/src/pages/orders/order-details.html', require('/src/pages/orders/order-details.html'));
    $templateCache.put('/src/pages/dashboard-selection/dashboard-selection.html', require('/src/pages/dashboard-selection/dashboard-selection.html'));

    $templateCache.put('/src/pages/employees/employee-details.html', require('/src/pages/employees/employee-details.html'));
    $templateCache.put('/src/pages/payments/payments.html', require('/src/pages/payments/payments.html'));
    $templateCache.put('/src/pages/payments/payment-details.html', require('/src/pages/payments/payment-details.html'));
    $templateCache.put('/src/pages/calender/calender.html', require('/src/pages/calender/calender.html'));
    $templateCache.put('/src/pages/calender/calender.html', require('/src/pages/calender/calender.html')); // Note: This is listed twice
    $templateCache.put('/src/pages/jobs/jobs.html', require('/src/pages/jobs/jobs.html'));
    $templateCache.put('/src/pages/jobs/job-details.html', require('/src/pages/jobs/job-details.html'));
    $templateCache.put('/src/pages/products/products.html', require('/src/pages/products/products.html'));
    $templateCache.put('/src/pages/products/product-details.html', require('/src/pages/products/product-details.html'));
    $templateCache.put('/src/pages/employees/employees.html', require('/src/pages/employees/employees.html'));
    $templateCache.put('/src/pages/documentation/documentation.html', require('/src/pages/documentation/documentation.html'));

    $templateCache.put('/src/pages/partials/individual-details.html', require('/src/pages/partials/individual-details.html'));
    $templateCache.put('/src/pages/partials/company-details.html', require('/src/pages/partials/company-details.html'));
    $templateCache.put('/src/pages/partials/table.html', require('/src/pages/partials/table.html'));
    $templateCache.put('/src/panel/availability-panel.html', require('/src/panel/availability-panel.html'));
    $templateCache.put('/src/panel/call-dialog.html', require('/src/panel/call-dialog.html'));
    $templateCache.put('/src/panel/car-panel.html', require('/src/panel/car-panel.html'));
    $templateCache.put('/src/panel/changes-panel.html', require('/src/panel/changes-panel.html'));
    $templateCache.put('/src/panel/delete-dialog.html', require('/src/panel/delete-dialog.html'));
    $templateCache.put('/src/panel/document-dialog.html', require('/src/panel/document-dialog.html'));
    $templateCache.put('/src/panel/emp-hour-rate-panel.html', require('/src/panel/emp-hour-rate-panel.html'));
    $templateCache.put('/src/panel/employee-overview.html', require('/src/panel/employee-overview.html'));
    $templateCache.put('/src/panel/mail-dialog.html', require('/src/panel/mail-dialog.html'));
    $templateCache.put('/src/panel/new-dialog.html', require('/src/panel/new-dialog.html'));
    $templateCache.put('/src/panel/paperclip-dialog.html', require('/src/panel/paperclip-dialog.html'));
    $templateCache.put('/src/panel/pay-dialog.html', require('/src/panel/pay-dialog.html'));


    $templateCache.put('/src/pages/settings/settings.html', require('/src/pages/settings/settings.html'));

    $templateCache.put('/src/pages/settings/cars.html', require('/src/pages/settings/cars.html'));
    $templateCache.put('/src/pages/settings/company-details.html', require('/src/pages/settings/company-details.html'));
    $templateCache.put('/src/pages/settings/create-depot.html', require('/src/pages/settings/create-depot.html'));
    $templateCache.put('/src/pages/settings/default-messages.html', require('/src/pages/settings/default-messages.html'));
    $templateCache.put('/src/pages/settings/edit-mail-templates.html', require('/src/pages/settings/edit-mail-templates.html'));
    $templateCache.put('/src/pages/settings/hourtypes.html', require('/src/pages/settings/hourtypes.html'));
    $templateCache.put('/src/pages/settings/notify-templates.html', require('/src/pages/settings/notify-templates.html'));
    $templateCache.put('/src/pages/settings/preview-mail-templates.html', require('/src/pages/settings/preview-mail-templates.html'));
    $templateCache.put('/src/pages/settings/product-groups.html', require('/src/pages/settings/product-groups.html'));
    $templateCache.put('/src/pages/settings/settings.html', require('/src/pages/settings/settings.html'));
    $templateCache.put('/src/pages/settings/upload-word-templates.html', require('/src/pages/settings/upload-word-templates.html'));
    $templateCache.put('/src/pages/settings/user-groups.html', require('/src/pages/settings/user-groups.html'));
    $templateCache.put('/src/pages/settings/job-kind.html', require('/src/pages/settings/job-kind.html'));
    $templateCache.put('/src/pages/settings/taxes.html', require('/src/pages/settings/taxes.html'));
    $templateCache.put('/src/pages/settings/customer-type.html', require('/src/pages/settings/customer-type.html'));
    $templateCache.put('/src/pages/settings/email-settings-form.html', require('/src/pages/settings/email-settings-form.html'));

    $templateCache.put('/src/pages/wizard/wizard.html', require('/src/pages/wizard/wizard.html'));
    $templateCache.put('/src/wizardpages/add-customer-form.html', require('/src/wizardpages/add-customer-form.html'));
    $templateCache.put('/src/wizardpages/add-employee-form.html', require('/src/wizardpages/add-employee-form.html'));
    $templateCache.put('/src/wizardpages/add-job-form.html', require('/src/wizardpages/add-job-form.html'));
    $templateCache.put('/src/wizardpages/add-location-form.html', require('/src/wizardpages/add-location-form.html'));
    $templateCache.put('/src/wizardpages/add-product-form.html', require('/src/wizardpages/add-product-form.html'));
    $templateCache.put('/src/wizardpages/add-products-form.html', require('/src/wizardpages/add-products-form.html'));
    $templateCache.put('/src/wizardpages/add-quote-form.html', require('/src/wizardpages/add-quote-form.html'));
    $templateCache.put('/src/wizardpages/add-order-form.html', require('/src/wizardpages/add-order-form.html'));
    $templateCache.put('/src/wizardpages/search-customer-form.html', require('/src/wizardpages/search-customer-form.html'));
    const selectedLanguage = getSelectedLanguage() || detectAndStoreLanguage();

    $translate.use(selectedLanguage);

    var original = $location.path;
    $location.path = function (path, reload, callback) {
        if (reload === false) {
            var lastRoute = $route.current;
            var un = $rootScope.$on('$locationChangeSuccess', function () {
                $route.current = lastRoute;
                if (callback)
                    callback();
                un();
            });
        }
        return original.apply($location, [path]);
    };

    editableThemes.bs3.inputClass = 'input-sm';
    editableThemes.bs3.buttonsClass = 'btn-sm';
    editableOptions.theme = 'bs3'; // bootstrap3 theme. Can be also 'bs2', 'default'

    datepickerPopupConfig.currentText = $filter('translate')('TODAY');
    datepickerPopupConfig.clearText = $filter('translate')('EMPTY');
    datepickerPopupConfig.closeText = $filter('translate')('CLOSE');

    $rootScope.version = settings.version;
    $rootScope.build = '';
    $rootScope.publishDate = settings.buildDate;
    $rootScope.gitCommitId = settings.gitCommitId;

    $api.get('v1/build', function (result) {
        console.log(result);
        if ($rootScope.build === '')
            return;

        if (parseInt(result) > $rootScope.build) {
            // window.location.reload(true)
            console.error('Not current version')
        }
    });


    $rootScope.currentUser = null;
    $rootScope.userRoles = USER_ROLES;
    $rootScope.isAuthorized = $auth.isAuthorized;
    $rootScope.currentUser = vxSession.user;

    var stoploading = false;

    var path = $location.path();
    if (path.indexOf('pssreset') > -1) {
        stoploading = true;
    }

    if (stoploading)
        return;

    if (angular.isUndefined(vxSession.user)
        || vxSession.user === null
        || vxSession.user.idUser === null) {
        $location.path('login');

    }

    $job.allKindOfParties();
    $job.allJobStatuses();
    $payment.getTaxes(null,true);
    $products.allProducts();
    $depot.getAllDepots(null, true);
    $customer.getAllTypes();
    $customer.getAllCustomerKinds(null, true);
    selectionFilters.employeeGroups(null, true);
    selectionFilters.employeeGroupsAvail(null, true);
    selectionFilters.customerStates(null, true);
    $users.getEventTypes();
    /// $users.getRoles();
    $auth.getAllCompanies(null,null,true);
});

