import dashboardModule from "../vxPanelGrid.module";

dashboardModule.directive('filterPanel', function() {
    return {
        restrict: 'E',
        scope: {
            data: '='  // Expects an object with keys: fields and model.
        },
        template: `
          <div class="filter-panel">
              <form name="filterForm" novalidate>
                  <!-- 
                    The auto-field directive is assumed to be provided by your library.
                    It automatically renders fields defined in data.fields and binds them to data.model.
                  -->
                  <auto:fields fields="data.fields" data="model" options="options"></auto:fields>
                  <button type="button" class="btn btn-primary filter-button" ng-click="applyFilter()">{{'APPLY_FILTER' | translate}}</button>
              </form>
          </div>
        `,
        link: function(scope, element) {

            scope.model = scope.data.model;

            scope.options = {

            };

            scope.applyFilter = function() {
                // Emit an event with the filter model so the dashboard controller can refresh data.
                scope.$emit('filterChanged', scope.model);
            };
        }
    };
});
