import app from "/src/app/app.module";


app.controller('product_groups',
    function ($scope, $timeout, msgBus, $products,
              $filter, $focus, sweet, vxUtils, toastr) {
        var vm = this;

        vm.subjectPlaceholder = vxUtils.loc('ADD_DESCRIPTION_PLACEHOLDER');
        vm.items = [];
        vm.selected = {content: ''};

        var getNewStartNr = function () {
            var result = $filter('max')(vm.items, 'productgroupstartnr');
            if (angular.isUndefined(result)) {
                return 100;
            } else {
                return result.productgroupstartnr + 100;
            }
        };

        vm.add = function () {
            var m = {
                productgroupname: vm.title,
                productgroupstartnr: getNewStartNr()
            };

            vm.title = '';
            vm.items.splice(0, 0, m);
            vm.selected = m;
            $focus('focusSubject');
            vm.save(true);
        };

        vm.delete = function () {
            sweet.show({
                title: vxUtils.loc('CONFIRMDELETION'),
                text: vxUtils.loc('REMOVEPRODUCTGROUP'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: vxUtils.loc('YES'),
                closeOnConfirm: true
            }, function () {
                $products.deleteProductgroup(function () {
                    var i = $filter('removeWith')(vm.items, {productgroupid: vm.selected.productgroupid});
                    // vm.items.splice(i, 1);
                    vm.items = i;
                    vm.selected = vm.items[vm.items.length - 1];
                }, vm.selected.productgroupid);
            });


        };

        vm.save = function (isNew) {
            $products.postProductgroup(function (result) {
                result.productgroupstartnr = parseInt(result.productgroupstartnr);

                if (isNew) {
                    vm.items.unshift(result); // Add to the beginning of the array
                } else {
                    // Find and update the existing item in the array
                    let index = vm.items.findIndex(item => item.productgroupid === result.productgroupid);
                    if (index !== -1) {
                        vm.items[index] = result; // Update the existing item
                    }
                }

                // Ensure selected item is correctly updated
                vm.selected = vm.items.find(item => item.productgroupid === result.productgroupid) || result;

                // Show success message
                toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('PRODUCTGROUP') + vxUtils.loc('ACTION_SAVED'));
            }, vm.selected);
        };

        vm.getItems = function () {
            $products.allProductGroups(function (result) {
                vm.items = result;

                angular.forEach(vm.items, function (v, k) {
                    v.productgroupstartnr = parseInt(v.productgroupstartnr);
                });


                if (vm.items.length > 0)
                    vm.selected = vm.items[0];
            });

        };

        vm.getItems();
    })
;