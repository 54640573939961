
import app from "/src/app/app.module";
import moment from "moment";


app.controller('calendar', function ($scope, uiCalendarConfig, $users,
                                     $job, selectionFilters, $filter, $locale,
                                     vxSession, vxUtils, msgBus, msgBusActions) {
    var date = new Date();
    var d = date.getDate();
    var m = date.getMonth();
    var y = date.getFullYear();
    $scope.nested = false;
    $scope.CalName = vxUtils.generateQuickGuid();

    var lastSelection = {};

    /* event source that contains custom events on the scope */
    $scope.events = [];
    /* event source that calls a function on every view switch */
    $scope.eventsF = function (start, end, really, callback) {
        lastSelection.start = start;
        lastSelection.end = end;
        lastSelection.callback = callback;

        var cal = {};

        if (angular.isUndefined($scope.panel)) {
            cal = $scope.tabitem.calendarType;
        } else {
            $scope.nested = true;
            cal = $scope.panel.calendarType;
        }
        $scope.type = cal.item;
        if (cal.item === 'employee' || $scope.session.authEquals(1)) {
            $users.getUserEvents(function (results) {
                    //$scope.events = results;

                    var ids = $filter('map')(results, 'jobid');
                    var clean = _.without(ids, undefined);
                    if (clean.length > 0) {

                        $job.getJobCrewStatus(function (data) {
                            var out = [];
                            for (var i = 0; i < results.length; i++) {
                                var item = results[i];
                                if (item.jobid === undefined) {
                                    //       console.log(item);
                                    continue;
                                }
                                var mail = $filter('filter')(data, {
                                    id_job: item.jobid.toString()
                                })[0];
                                if (mail === undefined)
                                    continue;

                                if (parseInt(mail.status) === 2) {
                                    continue;
                                }

                                item.userjobcrewstatus = mail.status;
                                item.userjobcrewstatusIcon = item.CalenderJobcrewIcon();
                                out.push(item);
                            }

                            $scope.events = out.concat($filter('filter')(results,{type:"availability"}));
                            //
                            callback($scope.events);
                        }, clean, [cal.id]);
                    } else {
                        $scope.events = results;
                        callback($scope.events);
                    }

                },
                cal.id, moment(start).unix(), moment(end).unix());
        }
        else if (cal.item === 'jobs' && $scope.session.auth(2)) {
            $job.getJobsBetween(function (results) {
                $scope.overviewCpi.all = results;
                $scope.overviewCpi.selectionChanged();


                // callback($scope.overviewCpi.all);
            }, moment(start).unix(), moment(end).unix());
        }

    };

    /* alert on eventClick */
    $scope.alertOnEventClick = function (event, allDay, jsEvent, view) {
        //console.log(event.title + ' was clicked ');
        if (angular.isUndefined(event))
            return;
        else if (moment.isMoment(event)) {
            return;
        }

        switch (event.type) {
            case 'job':
                msgBus.emitMsg(msgBusActions.shellOpenTabByType, {
                    type: 'job', id: event.tabID
                });
                break;
            case 'availability':
                var obj = vxUtils.findObjectOnParents($scope, 'PageHash', true);

                //obj.p.empExpPanelCpi.openNext(0, true);

                $('#actionwrapper'+obj.p.PageHash).scrollLeft( $('#actionwrapper'+obj.p.PageHash).width())

                msgBus.emitMsg(msgBusActions.calendarClicked, {
                    load: event, id: obj.p.PageHash
                });
                break;
        }

    };
    /* alert on Drop */
    $scope.alertOnDrop = function (event, dayDelta, minuteDelta, allDay, revertFunc, jsEvent, ui, view) {
        $scope.alertMessage = ('Event Droped to make dayDelta ' + dayDelta);
    };
    /* alert on Resize */
    $scope.alertOnResize = function (event, dayDelta, minuteDelta, revertFunc, jsEvent, ui, view) {
        $scope.alertMessage = ('Event Resized to make dayDelta ' + minuteDelta);
    };
    /* add and removes an event source of choice */
    $scope.addRemoveEventSource = function (sources, source) {
        var canAdd = 0;
        angular.forEach(sources, function (value, key) {
            if (sources[key] === source) {
                sources.splice(key, 1);
                canAdd = 1;
            }
        });
        if (canAdd === 0) {
            sources.push(source);
        }
    };
    /* add custom event*/
    $scope.addEvent = function () {
        $scope.events.push({
            title: 'Open Sesame',
            start: new Date(y, m, 28),
            end: new Date(y, m, 29),
            className: ['openSesame']
        });
    };
    /* remove event */
    $scope.remove = function (index) {
        $scope.events.splice(index, 1);
    };
    /* Change View */
    $scope.changeView = function (view, calendar) {
        uiCalendarConfig.calendars[calendar].fullCalendar('changeView', view);
    };
    /* Change View */
    $scope.renderCalender = function (cal) {
        if (uiCalendarConfig.calendars[cal]) {
            uiCalendarConfig.calendars[cal].fullCalendar('render');
        }
    };

    $scope.$on(
        "$destroy",
        function (event) {
            delete uiCalendarConfig.calendars[$scope.CalName];
        }
    );

    $scope.renderEvent = function (event, element, view) {

        var addIcon = function (icon, title) {

            if (!angular.isDefined(icon))
                return;

            var title = angular.isDefined(title) ? 'title="' + title + '"' : '';

            $(element).find('.fc-content')
                .prepend('<i style="margin-right:2px;margin-left:2px;" ' +
                'class="' + icon + '" ' + title + ' />');

        }

        switch (event.type) {
            case 'job':
                addIcon(event.userjobcrewstatusIcon, $filter('jobcrewStatus')(event.userjobcrewstatus));
                addIcon(event.calendarIcon, event.status);
                break;
            case 'availability':
                //addIcon(event.statusicon);
                break;
        }
        if (angular.isDefined(event.icon))
            addIcon(event.icon);
    };

    /* config object */
    $scope.uiConfig = {
        calendar: {
            weekNumbers: true,
            firstDay: 1,
            timezone: false,
            editable: false,
            header: {
                left: 'agendaDay,agendaWeek,month',
                center: 'title',
                right: 'today prev,next'
            },
            eventClick: $scope.alertOnEventClick,
            dayClick: $scope.alertOnEventClick,
            eventDrop: $scope.alertOnDrop,
            eventResize: $scope.alertOnResize,
            eventRender: $scope.renderEvent,
            timeFormat: $locale.DATETIME_FORMATS.shortTime
        }
    };

    $scope.overviewCpi = {
        openItemPage: "/src/pages/jobs/job-details.html",
        openItemIcon: "fa fa-map-marker",
        type: 'job',
        filters: selectionFilters.jobStates,
        selectionChanged: function () {

            var fields = ['status', 'crewstatus'];
            $scope.overviewCpi.items = selectionFilters.filterBySelection(
                $scope.overviewCpi.filters,
                $scope.overviewCpi.all,
                fields
            );
            $scope.eventSources[0] = $scope.overviewCpi.items;
            if (angular.isDefined(lastSelection.callback)) {
                lastSelection.callback();
                lastSelection.callback = undefined;
            } else {

            }

        }
    };

    $scope.refresh = function () {
        $scope.eventSources = [$scope.events, [], $scope.eventsF];
    };

    msgBus.onMsg(msgBusActions.calendarReload, function (sender, mssg) {
        var obj = vxUtils.findObjectOnParents($scope, 'PageHash');
        if (mssg === obj) {
            //   $scope.eventsF( lastSelection.start,lastSelection.end,false);
            if (uiCalendarConfig.calendars[$scope.CalName]) {
                uiCalendarConfig.calendars[$scope.CalName].fullCalendar('refetchEvents');
            }
        }

    }, $scope);

    /* event sources array*/
    $scope.eventSources = [$scope.events, [], $scope.eventsF];

    setTimeout(function (){
        if (uiCalendarConfig.calendars[$scope.CalName]) {
            uiCalendarConfig.calendars[$scope.CalName].fullCalendar('render');
        }
    },100)


})
