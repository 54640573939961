import app from "/src/app/app.module";
import EmailSettings from "../../models/email-settings";

app.controller('email-settings-controller', function ($scope, $api, vxForms, toastr, vxUtils) {
    // Load default settings
    $scope.settings = {
        USE_SMTP: true,
        USE_SMTP_AUTH: true,
        SENDERNAME: "Planning - Oesterplanning.nl",
        SENDER: "noreply@oesterplanning.nl",
        SMTP_HOST: "smtp.mail.pcextreme.nl",
        SMTP_USER: "",
        SMTP_PASS: "",
        SMTP_PORT: 25
    };
    // Define form fields
    $scope.fields = vxForms.emailSettings;

    $api.get($api.adress.settingsEmail, function (data) {
        if (data) {
            $scope.settings = data;
        }
    }, EmailSettings);



    // Form layout and validation settings
    $scope.options = {
        validation: { enabled: true },
        layout: { type: 'vertical', labelSize: 3, inputSize: 9 }
    };

    // Save settings function
    $scope.saveSettings = function () {
        $api.post($api.adress.settingsEmail, succes=>{ toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('CHANGESUCCESFULL'));},$scope.settings);
    };

    $scope.testEmail = "";

    // Send test email function
    $scope.sendTestMail = function () {
        if (!$scope.testEmail) {
            toastr.error(vxUtils.loc('REQUIRED'), "Please enter a valid email address.");
            return;
        }

        let url = $api.adress.sendTestMail + "?email=" + encodeURIComponent($scope.testEmail);

        $api.get(url, function (response) {
            toastr.success(vxUtils.loc('SUCCES'), vxUtils.loc('TESTMAIL_SUCCESFULL'));
        }, function (error) {
            toastr.error("Failed to send test email. Please check SMTP settings.", "Error");
        });
    };
});
