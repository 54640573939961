import dashboardModule from "../vxPanelGrid.module";

dashboardModule.directive('chartPanel', function() {
    return {
        restrict: 'E',
        scope: {
            data: '=' // expects data to have properties: labels, datasets, and optionally options
        },
        template: `
        <div>
              <canvas class="chart chart-base"
              
                      chart-data="data.data"
                      chart-type="data.type"
                      chart-labels="data.labels"
                      chart-series="data.series"
                      chart-colors="data.colors"
                      chart-dataset-override="data.datasetOverride"
                      chart-options="data.options">
              </canvas>
         </div>
        `,
        link: function(scope, element) {
            // No need to manually initialize Chart.js.
            // angular-chart.js takes care of that.


        }
    };
});
