'use strict';

var ChangelogEntry = function (entry) {

    angular.extend(this, entry);

    this.dbo = entry;

    this.Version = function () {
        return this.version;
    };

    this.Date = function () {
        return this.date;
    };

    this.Message = function () {
        return this.message;
    };

    this._version = this.Version();
    this._date = this.Date();
    this._message = this.Message();
    this._type = 'changelog';
};

export default ChangelogEntry;
