
import dashboardModule from '../vxPanelGrid.module';

dashboardModule.directive('kpiPanel', function() {
    return {
        restrict: 'E',
        scope: {
            data: '='  // Expects an object with keys: title, value, changePercent, timeframe, isPositive
        },
        template: `
     <div class="kpi-panel-container">
      <div class="kpi-title">{{ data.title }}</div>
      <div class="kpi-value-container">
      <div class="kpi-value">{{ data.value | number }}</div>
    
      <div class="kpi-change" ng-if="data.changePercent !== null"
           ng-class="{'positive': data.isPositive, 'negative': !data.isPositive}">
        <span class="arrow" 
              ng-class="{'arrow-up': data.isPositive, 'arrow-down': !data.isPositive}">
        </span>
        {{ data.changePercent | number:1 }}%
      </div>
      </div>
    
      <div class="kpi-timeframe">{{ data.timeframe }}</div>
    </div>
    `
    };
});
