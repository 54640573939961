
import app from "/src/app/app.module";
var api = function ($resource, settings, vox, $localStorage, $filter) {

    var adress = {
        usergroups: 'v1/usergroups',
        kindofparties: 'v1/jobs/kindofparties',
        kindofparties_counted: 'v1/jobs/kindofparties/counted',
        notifications: 'v1/notifications',
        stats: 'v1/stats/global',
        orderStats: 'v1/stats/orders/global',
        charts: 'v1/stats/charts',
        ordersCharts: 'v1/stats/orders/charts',
        phoneNumbers: 'v1/notifications/phone',
        changes: 'v1/changes'
        , cars: 'v1/cars'
        , plancar: 'v1/plancar'
        , carsplanned: 'v1/plannedcars'
        ,createpaytransaction:'v1/pay/create'
        ,pay:'v1/pay',
        jobs:'v1/jobs',
        setPaymentNR:'v1/setpaymentnumber',
        setPaymentNROrder:'v1/setpaymentnumber/order',
        hourtypes:'v1/hourtypes',
        hourrate:'v1/hourrates',
        hourrateuser:'v1/hourrates/user',
        customerKinds: 'v1/customerkinds',
        settingsEmail: 'v1/settings/email',
        sendTestMail: 'v1/mail/test',
        changelog:'v1/changelog',
        dashboard:'v1/dashboard'
    };

    var convertToObjGeneric = function (values, objectType, unpack = true) {
        if (!values) return null;

        // If `values` is already an instance of `objectType`, return it directly
        if (values instanceof objectType) {
            return values;
        }

        // If `values` is an array, process each item
        if (Array.isArray(values)) {
            var temp = [];
            angular.forEach(values, function (value) {
                var obj = value instanceof objectType ? value : new objectType(value);
                this.push(obj);
            }, temp);
            return temp.length === 1 && unpack ? temp[0] : temp;
        }

        // If it's a single object but not an instance, convert it
        return new objectType(values);
    };



    var get = function (url, succes, object, error, unpack) {
        vox.get(settings.BASE_API_URL + url, function (data) {
            var out = data;
            if (object) {
                out = convertToObjGeneric(out, object, unpack);
            }

            if (succes)
                succes(out);
        }, function (data) {
            if (error)
                error(data);
        });
    };

    var post = function (url, succes, item, object, error, unpack) {
        vox.post(settings.BASE_API_URL + url, item, function (data) {
            var out = data;
            if (object) {
                out = convertToObjGeneric(out, object, unpack);
            }

            if (succes)
                succes(out);
        }, function (data) {
            if (error)
                error(data);
        });
    };


    var del = function (url, callback, error) {
        vox.delete(settings.BASE_API_URL + url, callback, error);
    };


    return {
        adress: adress,
        get: get,
        post: post,
        del: del
    };
};

app.factory("$api", api);