'use strict';

import moment from "moment";

var Job = function (job, $filter) {

    if (job === undefined) {
        return;
    }

    this.dbo = job;

    this.IsCompany = this.dbo['iscompany'] === 'true';

    var prefix = "loc";

    this.IDWarehouse = function () {
        return parseInt(this.dbo['id_warehouse']);
    };

    this.IDLocation = function () {
        return parseInt(this.dbo['idLocation']);
    };

    this.PaymentID = function () {
        return parseInt(this.dbo['id_payment']);
    };

    this.InvoiceAdressId = function () {
        return parseInt(this.dbo['id_invoice']);
    };

    this.JobID = function () {
        return parseInt(this.dbo['idJob']);
    };

    this.ClientNumber = function () {
        return parseInt(this.dbo['idClient']);
    };

    //properties
    this.ClientName = function () {
        if (this.IsCompany)
            return this.dbo['companyname'];
        else {
            var prep = this.dbo['client_middlename'];
            if (prep !== "" && prep !== null)
                prep = " " + prep;
            else {
                prep ='';
            }
            var lastname = this.dbo['client_lastname']??'';
            return this.dbo['client_firstname'] + prep + " " + lastname;
        }
    };

    this.UserName = function () {
        if (this.IsCompany)
            return this.dbo['companyname'];
        else {
            var prep = this.dbo['user_middlename'];
            if (prep !== "" && prep !== null)
                prep = " " + prep;
            else {
                prep ='';
            }

            return this.dbo['user_firstname'] + prep + " " + this.dbo['user_lastname'];
        }
    };

    this.StartDate = function () {
        return this.dbo['startdate'] === null ? '-' : parseInt(this.dbo['startdate'] + "000");
    };

    this.StartTime = function () {
        return this.dbo['startdate'] === null ? '-' : parseInt(this.dbo['startdate'] + "000");
    };

    this.EndDate = function () {
        return this.dbo['enddate'] === null ? '-' : parseInt(this.dbo['enddate'] + "000");
    };

    this.EndTime = function () {
        return this.dbo['enddate'] === null ? '-' : $filter('date')(parseInt(this.dbo['enddate'] + "000"), 'HH:mm'); //todo check time
    };

    this.Entrance = function () {
        return this.dbo['entrance'] === null ? '-' : parseInt(this.dbo['entrance'] + "000");
    };

    this.EntranceHours = function () {
        return this.dbo['entrance'] === null ? '-' : $filter('date')(parseInt(this.dbo['entrance'] + "000"), 'HH:mm'); //todo check time
    };

    this.DueDate = function () {
        if (this.dbo['experation_date'] === undefined) {
            if (this.dbo['endoptiondate'] === undefined) {
                return '-';
            } else {
                return parseInt(this.dbo['endoptiondate'] + "000");
            }
        } else {
            return this.dbo['experation_date'] === null ? '-' : parseInt(this.dbo['experation_date'] + "000");
        }
    };

    this.CreationDate = function () {
        return this.dbo['jobcreationdate'] === null ? '-' : parseInt(this.dbo['jobcreationdate'] + "000");
    };

    this.CrewCreationDate = function () {
        return this.dbo['creationdate'] === null ? '-' : parseInt(this.dbo['creationdate'] + "000");
    };

    this.SendDate = function () {
        return this.dbo['send_date'] === null ? '-' : parseInt(this.dbo['send_date'] + "000");
    };

    this.Status = function () {
        return this.dbo['jobstatusname'];
    };

    this.FactuurNr = function () {
        return this.dbo['factuurnr'];
    };

    this.GuestCount = function () {
        return parseInt(this.dbo['guestcount']);
    };

    this.AreaCode = function () {
        return this.dbo[prefix + '_areacode'];
    };

    this.Street = function () {
        return this.dbo[prefix + '_street'];
    };

    this.Number = function () {
        return this.dbo[prefix + '_number'];
    };

    this.City = function () {
        return this.dbo[prefix + '_city'];
    };

    this.State = function () {
        return this.dbo[prefix + '_state'];
    };

    this.Country = function () {
        return this.dbo[prefix + '_country'];
    };

    this.Spot = function () {
        return this.dbo[prefix + '_spot'];
    };

    this.JobTitle = function () {
        return this.dbo['jobtitle'];
    };

    this.JobNumber = function () {
        return parseInt(this.dbo['jobnummer']);
    };

    this.CrewSlots = function () {
        return parseInt(this.dbo['crewslots']);
    };

    this.CrewAccepted = function () {
        return this.dbo['accepted'] === null ? 0 : parseInt(this.dbo['accepted']);
    };

    this.CrewDeclined = function () {
        return this.dbo['declined'] === null ? 0 : parseInt(this.dbo['declined']);
    };

    this.CrewWaiting = function () {
        return this.dbo['waiting'] === null ? 0 : parseInt(this.dbo['waiting']);
    };

    this.Amount = function () {
        return parseFloat(this.dbo['amount']);
    };

    this.KindOfJob = function () {
        return this.dbo['kindofparty'] || this.dbo['type'];
    };

    this.PaymentState = function () {
        return !this.dbo['state'] ? this.dbo['state_old'] : this.dbo['state'];
    };

    this.PaymentType = function () {
        return !this.dbo['type'] ? this.dbo['type_old'] : this.dbo['type'];
    };

    //Icons

    this.IsPayedIcon = function () {

        var val = "fa fa-asterisk fa-05x not-important";
        switch (this.paymentstate) {
            case 'new':
                val = "fa fa-money fa-05x ";
                break;
            case 'send':
                val = "fa fa fa-paper-plane green fa-05x";
                break;
            case 'payment':
                val = "fa fa-money green fa-05x";
                break;
            case 'overdue':
                val = "fa fa-money red fa-05x";
                break;
        }

        return val;
        //console.log('r item resolved here ',row);
        //    if ((row.age)>30)   { return 1}   else  { return 0}

    };

    this.PaymentTypeIcon = function () {

        var val = "fa fa-asterisk fa-05x not-important";
        switch (this.paymenttype) {
            case "quote":
                val = "fa fa-clock-o fa-05x ";
                break;
            case "invoice":
                val = "fa fa-eur fa-05x";
                break;
        }

        return val;
        //console.log('r item resolved here ',row);
        //    if ((row.age)>30)   { return 1}   else  { return 0}

    };
    this.StatusIcon = function () {

        var val = "fa fa-clock-o";

        switch (this.status) {
            case 'Annulering':
                val = "fa fa-ban red fa-05x ";
                break;
            case 'Optie':
                val = "fa fa-exclamation-circle yellow fa-05x";
                break;
            case 'Bevestigd':
                val = "fa fa-check-circle green fa-05x";
                break;
        }
        return val;
        //console.log('r item resolved here ',row);
        //    if ((row.age)>30)   { return 1}   else  { return 0}

    };

    this.CrewStatus = function () {
        var crewslots = this.CrewSlots();
        var crewdeclined = this.CrewDeclined();
        var crewaccepted = this.CrewAccepted();
        var crewwaiting = this.CrewWaiting();

        if (crewdeclined === 0 && crewaccepted === 0 && crewwaiting === 0) {
            return 'empty';
        }
        else if (crewaccepted === crewslots) {
            return 'full';
        }
        else if (crewaccepted < crewslots) {
            return 'toofew';
        }
        else if (crewaccepted > crewslots) {
            return 'overbooked';
        }
    };

    this.HasProductIcon = function () {
        var val = "fa fa-shopping-cart fa-05x";
        var i = this.Amount();
        if (isNaN(i)) {
            val = "fa fa-shopping-cart fa-ban red fa-05x";
        }
        return val;
    };

    this.isConfirmedIcon = function () {
        var val = "fa fa-asterisk not-important fa-05x";

        if (this.isconfirmed) {
            if (!this.isread)
                val = "ionicons ion-email-unread fa-05x";
            else
                val = "ionicons ion-email green fa-05x";

        }
        return val;
    };

    this.HasProduct = function () {
        var val = "Ja";
        var i = this.Amount();
        if (isNaN(i)) {
            val = "Nee";
        }
        return val;
    };

    this.CustomerType = function () {

        var val = "relation";

        switch (this.IsCompany) {
            case true:
                val = "company";
                break;
            case false:
                val = "personal";
        }
        return val;
    };

    this.CustomerTypeIcon = function () {

        var val = "fa fa-building-o fa-05x";

        switch (this.IsCompany) {
            case true:
                val = "fa fa-building-o fa-05x";
                break;
            case false:
                val = "glyphicon glyphicon-user fa-05x";
        }
        return val;
    };

    this.CreatedBy = function () {
        return parseInt(this.dbo['id_createdbyuser']);
    };

    this.IsOpen = function () {
        return parseInt(this.dbo['isopen']);
    };

    this.Icon = function () {
        return 'fa fa-map-marker';
    };
    this.CalenderIcon = function () {

        var val = "vx-orange";

        switch (this.status) {
            case 'Annulering':
                val = "fa fa-square red";
                break;
            case 'Optie':
                val = "fa fa-square yellow";
                break;
            case 'Bevestigd':
                val = "fa fa-square green";
                break;
        }
        return val;
        //console.log('r item resolved here ',row);
        //    if ((row.age)>30)   { return 1}   else  { return 0}

    };

    this.CalenderJobcrewIcon = function () {

        var val = undefined;

        switch (parseInt(this.userjobcrewstatus)) {
            case 2:
                val = "fa fa-thumbs-o-down";
                break;
            case 0:
                val = "fa fa-clock-o yellow ";
                break;
            case 1:
                val = "fa fa-thumbs-o-up";
                break;
        }
        return val;
        //console.log('r item resolved here ',row);
        //    if ((row.age)>30)   { return 1}   else  { return 0}

    };

    this.HasCars = function () {
        var i = this.dbo['hascars'];
        return i;
    };

    this.HasCarsIcon = function () {

        var val = this.HasCars();
        val = val === null ? "fa fa-asterisk not-important fa-05x" :
            "ionicons ion-android-car fa-05x";
        return val;
        //console.log('r item resolved here ',row);
        //    if ((row.age)>30)   { return 1}   else  { return 0}

    };

    this.JobcrewStatus = function () {
        var i = parseInt(this.dbo['crewstatus'] || this.dbo['status']);
        return isNaN(i) ? undefined : i;
    };

    this.Background = function () {
        switch ('') {
            case 'outgoing':
                return 'red-bg';
                break;
            case 'incoming':
                return 'green-bg';
                break;
            case 'planned':
                return 'yellow-bg';
                break;
            default:
                return 'vx-blue-bg';
                break;
        }
    };

    this.Remarks = function () {
        return this.dbo['remarks'];
    };

    this.IdUser = function () {
        return this.dbo['id_user'];
    };

    this.Adress = function () {
        return (this.street || '') + ' ' + (this.streetnumber || '');
    };

    this.IdJobcrew = function () {
        return this.dbo['idJobcrew'];
    };

    this.TendantId = function () {
        return parseInt(this.dbo['tendant_id_FK']);
    };
    this.TendantColor = function () {
        return this.dbo['vox_tendants_color'];
    };

    this.OfferteID = function () {
        return parseInt(this.dbo['idofferte']);
    };

    //TAB interface
    this.tabID = this.JobNumber();
    this.tabDescription = this.JobTitle();

    //Client
    this.idClient = this.ClientNumber();
    this.customericon = this.CustomerTypeIcon();
    this.customertype = this.CustomerType();
    this.clientname = this.ClientName();

    //Job

    this.hascars = this.HasCars();
    this.hascarsicon = this.HasCarsIcon();
    this.isconfirmed = false;
    this.isread = false;
    this.isconfirmedicon = this.isConfirmedIcon();
    this.userjobcrewstatus = this.JobcrewStatus();
    this.userjobcrewstatusIcon = this.CalenderJobcrewIcon();
    this.status = this.Status();
    this.calendarIcon = this.CalenderIcon();
    this.paymentid = this.PaymentID();
    this.invoiceadressid = this.InvoiceAdressId();
    this.paymentstate = this.PaymentState();
    this.paymenttype = this.PaymentType();
    this.jobtitle = this.JobTitle();
    this.jobnumber = this.JobNumber();
    this.crewslots = this.CrewSlots();
    this.crewdeclined = this.CrewDeclined();
    this.crewaccepted = this.CrewAccepted();
    this.crewwaiting = this.CrewWaiting();
    this.crewstatus = this.CrewStatus();
    this.jobid = this.JobID();
    this.entrance = this.Entrance();
    this.entrancetime = this.EntranceHours();
    this.guestcount = this.GuestCount();
    this.statusicon = this.StatusIcon();
    this.ispayedicon = this.IsPayedIcon();
    this.payementtypeicon = this.PaymentTypeIcon();
    this.startdate = this.StartDate();
    this.starttime = this.StartTime();
    this.enddate = this.EndDate();
    this.endtime = this.EndTime();
    this.hasproductsicon = this.HasProductIcon();
    this.hasproducts = this.HasProduct();
    this.amount = this.Amount();
    this.duedate = this.DueDate();
    this.kindofjob = this.KindOfJob();
    this.createdbyID = this.CreatedBy();
    this.jobcreationdate = this.CreationDate();
    this.factuurnr = this.FactuurNr();
    this.isopen = this.IsOpen();
    this.idWarehouse = this.IDWarehouse();
    this.remarks = this.Remarks();
    this.idofferte = this.OfferteID();
    //employee jobcrew
    this.idUser = this.IdUser();
    this.idjobcrew = this.IdJobcrew();
    this.creationdate = this.CrewCreationDate();
    this.idjob = this.JobID();

    //Location
    this.spot = this.Spot();
    this.street = this.Street();
    this.streetnumber = this.Number();
    this.areacode = this.AreaCode();
    this.city = this.City();
    this.state = this.State();
    this.country = this.Country();
    this.idLocation = this.IDLocation();

    this.adress = this.Adress();

    //TIMELINE
    this.type = 'job';
    this.time = this.StartDate();
    this.icon = this.Icon();
    this.background = this.Background();

    // this.userjobcrewstatus = parseInt(this.dbo['status']);
    // this.userjobcrewstatusIcon = this.CalenderJobcrewIcon();

    this.tendant = this.TendantId();
    this.tendantColor = this.TendantColor();
    this.infoIcon = 'fa fa-info-circle';
    //Calendar
    this.title = this.jobtitle;
    this.start = moment.unix(parseInt(this.dbo['entrance'] || this.dbo['startdate'])).format("YYYY-MM-DDTHH:mm:ss");
    this.end = moment.unix(parseInt(this.dbo['enddate'])).format("YYYY-MM-DDTHH:mm:ss");

    this.className = this.background;

    this.toString = function ($locale) {
        var sFormat = "{0}: {1} van {2} tot {3} in {4} ";
        var result = bob.string.formatString(sFormat,
            this.jobnumber, this.jobtitle,
            moment(this.entrance).format($locale.DATETIME_FORMATS.shortTime), moment(this.enddate).format($locale.DATETIME_FORMATS.shortTime), this.city);
        return result;
    };

};

export default Job;

//[
//               { background: 'danger', icon: 'fa fa-credit-card', title: 'Test', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789', message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vel justo eu mi scelerisque vulputate. Aliquam in metus eu lectus aliquet egestas.' },
//               { background: 'warning', icon: 'fa fa-save', title: 'Test2', time: '123456789' }
//];