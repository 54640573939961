import dashboardModule from "../vxPanelGrid.module";

dashboardModule.directive('htmlPanel', function($sce) {
        return {
            restrict: 'E',
            scope: {
                content: '='
            },
            template: '<div ng-bind-html="trustedContent"></div>',
            link: function(scope) {
                // Trust the HTML content so it can be rendered correctly
                scope.trustedContent = $sce.trustAsHtml(scope.content.content);
            }
        };
    });