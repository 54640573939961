import app from "/src/app/app.module";


app.controller('settings', function ($scope, settings, $filter, vxUtils, $timeout) {
    $scope.settingsPanelCpi = {
        panels: [
            {
                label: $filter('translate')('DEFAULT_MESSAGE'), width: 300,
                selectionpane: true,
                selections: [
                    {label: vxUtils.loc('EMAIL_TEMPLATES'), value: 'mail', selected: false},
                    {label: vxUtils.loc('EMAIL_SETTINGS'), value: 'mail-settings', selected: false},
                    {label: vxUtils.loc('WORD_TEMPLATES'), value: 'word', selected: false},
                    {label: vxUtils.loc('HTML_TEMPLATES'), value: 'html', selected: false},
                    {label: vxUtils.loc('NOTIFY_EMAIL'), value: 'notify', selected: false},
                    {label: vxUtils.loc('COMPANY_DATA'), value: 'default', selected: false},
                    {label: vxUtils.loc('USER_GROUPS'), value: 'usergroups', selected: false},
                    {label: vxUtils.loc('DEPOT'), value: 'depots', selected: false},
                    {label: vxUtils.loc('TAXES'), value: 'taxes', selected: false},
                    {label: vxUtils.loc('CARS'), value: 'cars', selected: false},
                    {label: vxUtils.loc('PRODUCTGROUPS'), value: 'productgroups', selected: false},
                    {
                        label: $filter('translate')('HOURTYPES')
                        , value: 'hourtypes', selected: false
                    },
                    {
                        label: $filter('translate')('JOB_KINDSOFPARTY')
                        , value: 'jobkindmanagement', selected: false
                    },
                    {
                        label: $filter('translate')('CUSTOMER_TYPES')
                        , value: 'customer_type', selected: false
                    },
                ],
                icon: "fa fa-cogs", toggle: true, guid: vxUtils.generateQuickGuid()
            }
        ],
        children: [
            {
                label: $filter('translate')('DEFAULT_MESSAGE'), width: 550,
                href: "/src/pages/settings/notify-templates.html",
                parent: 'notify', filter: true,
                icon: "fa fa-group", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('USER_GROUPS'), width: 400,
                href: "/src/pages/settings/user-groups.html",
                parent: 'usergroups', filter: true,
                icon: "fa fa-group", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('DEFAULT_MESSAGE'), width: 550,
                href: "/src/pages/settings/edit-mail-templates.html",
                parent: 'mail', filter: true,
                icon: "fa fa-group", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('DEFAULT_MESSAGE'), width: 1000,
                href: "/src/pages/settings/preview-mail-templates.html",
                parent: 'mail', filter: true,
                icon: "fa fa-group", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('UPLOADOFFICEWORD'), width: 600,
                href: "/src/pages/settings/upload-word-templates.html",
                parent: 'word',
                icon: "fa fa-group", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('DEFAULT_MESSAGE'), width: 1000,
                href: "/src/pages/settings/edit-mail-templates.html",
                parent: 'html', isPdf: true,
                icon: "fa fa-group", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('DEFAULT_MESSAGE'), width: 400,
                href: "/src/pages/settings/preview-mail-templates.html",
                parent: 'html', isPdf: true,
                icon: "fa fa-group", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('CREATE_DEPOT'), width: 400,
                href: "/src/pages/settings/create-depot.html",
                parent: 'depots',
                icon: "fa fa-building", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('MANAGECOMPANIES'), width: 400,
                href: "/src/pages/settings/company-details.html",
                parent: 'default',
                icon: "fa fa-building", toggle: false,
                class: "vx-adress-container setting-company-panel",
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('CARS'), width: 400,
                href: "/src/pages/settings/cars.html",
                parent: 'cars',
                icon: "fa fa-car", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('PRODUCTGROUPS'), width: 400,
                href: "/src/pages/settings/product-groups.html",
                parent: 'productgroups',
                icon: "fa fa-cogs", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('HOURTYPES'), width: 400,
                href: "/src/pages/settings/hourtypes.html",
                parent: 'hourtypes',
                icon: "fa fa-hourglass-end", toggle: false,
                guid: vxUtils.generateQuickGuid()
            }  ,
            {
                label: $filter('translate')('JOB_KINDSOFPARTY'), width: 400,
                href: "/src/pages/settings/job-kind.html",
                parent: 'jobkindmanagement',
                icon: "fa fa-hourglass-end", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },{
                label: $filter('translate')('CUSTOMER_TYPES'), width: 400,
                href: "/src/pages/settings/customer-type.html",
                parent: 'customer_type',
                icon: "fa fa-hourglass-end", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('TAXES'), width: 400,
                href: "/src/pages/settings/taxes.html",
                parent: 'taxes',
                icon: "fa fa-hourglass-end", toggle: false,
                guid: vxUtils.generateQuickGuid()
            },
            {
                label: $filter('translate')('EMAIL_SETTINGS'), width: 400,
                href: "/src/pages/settings/email-settings-form.html",
                parent: 'mail-settings',
                icon: "fa fa-hourglass-end", toggle: false,
                guid: vxUtils.generateQuickGuid()
            }
        ]
    };

    var timer = $timeout(function () {
        $scope.settingsPanelCpi.closeAll();
    }, 250);

    $scope.$on(
        "$destroy",
        function (event) {

            $timeout.cancel(timer);

        }
    );

    $scope.checked = false;

    $scope.settingPages = [
        {
            label: '',
            href: "/src/pages/settings/default-messages.html", icon: "fa fa-envelope-o"
        },
        {label: '', href: "/src/pages/settings/default-messages.html", icon: "glyphicon glyphicon-user"}];

});